import * as actions from './ActionTypes';
import clientAxios from './clientAxios';


//
// GET ALL MATERIAS BY PROFESOR
//
//P-1

export function getMateriasAction(){
    return async (dispatch) => {
        console.log('Si me estoy activando')
        dispatch(descargarMaterias())
        
        try {
            const response = await clientAxios.get('/materias')
            console.log(response)
          // const returnedArray = Array.from(response.data.results.materias) //response.data.results
            dispatch(descargaMateriasExitosa(response.data.data.results)) //APi real : response.data.data.results
            //Puedo enviarlo entre parentesis o referenciar en el object el nombre del obj del response
        } catch (error) {
            dispatch(descargaMateriasError())
            console.log(error)
        }
    }
}
const descargarMaterias = () => ({
    type: actions.MATERIAS_GET_ALL_INIT,
    payload: true 
})

const descargaMateriasExitosa = (results) => ({
    type: actions.MATERIAS_GET_ALL_SUCCESS,
    payload: results
})

const descargaMateriasError = () => ({
    type: actions.MATERIAS_GET_ALL_ERROR,
    payload: true
})



export function getMateriasByProfesorAction (profesorID){
    return async (dispatch) => {
        dispatch(descargarMateriasByUser())
        
        try {

            const response = await clientAxios.get(`/profesor/${profesorID}/materias`)
            console.log(response)
            dispatch(descargaMateriasByUserExitosa(response.data.data.results)) //APi real : response.data.data.results

        } catch (error) {
            dispatch(descargaMateriasByUserError())
            console.log(error)
        }
    }
}

export function getMateriasByEstudianteAction (estudianteID){
    return async (dispatch) => {
        dispatch(descargarMateriasByUser())
        
        try {

            const response = await clientAxios.get(`/estudiante/${estudianteID}/materias`)
            console.log(response)
            dispatch(descargaMateriasByUserExitosa(response.data.data.results)) //APi real : response.data.data.results

        } catch (error) {
            dispatch(descargaMateriasByUserError())
            console.log(error)
        }
    }
}


const descargarMateriasByUser = () => ({
    type: actions.MATERIAS_GET_BY_USER_INIT,
    payload: true 
})

const descargaMateriasByUserExitosa = (results) => ({
    type: actions.MATERIAS_GET_BY_USER_SUCCESS,
    payload: results
})

const descargaMateriasByUserError = () => ({
    type: actions.MATERIAS_GET_BY_USER_ERROR,
    payload: true
})


//
// GET 1 MATERIA BY ID
//
//P-1
export function getMateriaByIDAction (materiaID){
    console.log(materiaID)
    return async (dispatch) => {
        dispatch (getMateria(materiaID))
        
        try {
            const response = await clientAxios.get(`/materia/${materiaID}`)
            console.log(response)
            dispatch(getMateriaSuccess(response.data.data.results)) //Esto ya se notifico por CU pero aun no se ha corregido
               //Puedo enviarlo entre parentesis o referenciar en el object el nombre del obj del response
        } catch (error) {
            dispatch(getMateriaError())
        }
    }
}
//P1
const getMateria = (materiaID) => ({
    type: actions.MATERIA_GET_BY_ID_INIT,
    payload: {id: materiaID, isProcessing: true, deleteItem: null} 
})
//P-1A
const getMateriaSuccess = (result) => ({
    type: actions.MATERIA_GET_BY_ID_SUCCESS,
    payload: result
})
//P-1B
const getMateriaError = () => ({
    type: actions.MATERIA_GET_BY_ID_ERROR,
    payload: true
})