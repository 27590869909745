import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { DashEstudiantePage } from './Admin/DashEstudiantePage'
import { MensajesPage } from './Mensajes/MensajesPage'


import { MateriasPage } from './Materias/MateriasPage'
import { MateriaDetail } from './Materias/SecondClaseBox'
import { ClaseDetailEstudiante } from './Clases/ClaseDetailEstudiante'
import ProfileLayout from './Profile/ProfileLayout'
import * as HelperFunctions from '../../Helpers/Functions';


import PropTypes from 'prop-types';
import NavBar from './Nav/NavBar';
import TopNavBar from './Nav/TopNavBar';

import '../../App.css';
import '../../panel_layout.css';

class DashboardEstudianteLayout extends React.Component {

    
    render(){
        const { match } = this.props
        //for class dashboard change
        const slug = HelperFunctions.slugify( this.props.location.pathname)

        return(
            <>
            <NavBar />
            <div className={`container-fluid container-content ${slug === "panel-perfil" ? "no-right-padding" : ""}`}>
                <div className="row vh-100 ">
                    <div id="dashboard" className={`dash-estudiante vh-100 col-12 nopadding ${slug}`}>
                        <TopNavBar></TopNavBar>
                        <div className="dash-content">
				            <div className="row" >
                            <Switch>
                                <Route 
                                    exact path={`${match.path}`}
                                    render={(props) => <DashEstudiantePage {...props} /> }
                                />
                                <Route path={`${match.path}/materias/`}      component={MateriasPage} />
                                <Route path={`${match.path}/materia/:id`}    component={MateriaDetail} />
                                <Route path={`${match.path}/:materia/clase/:id`}   component={ClaseDetailEstudiante} />
                                <Route path={`${match.path}/perfil`} component={ProfileLayout} />
                                <Route path={`${match.path}/mensajes`}       component={MensajesPage} />  
                            </Switch>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
            
        )
    }
}
DashboardEstudianteLayout.propTypes = {
    match: PropTypes.any.isRequired
}

export default DashboardEstudianteLayout;
