import React, { useEffect } from 'react'
import {  useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { DashProfesorPage } from './Admin/DashProfesorPage'
// import InscripcionAdd from './Inscripciones/InscripcionAdd'
// import InscripcionEdit from './Inscripciones/InscripcionEdit'
// import { EstudianteDetail } from './Estudiantes/EstudianteDetail'
import { MateriasPage } from './Materias/MateriasPage'
import { MateriaDetail }  from './Materias/SecondClaseBox'//MateriaDetail'
import { ClaseDetail }  from './Clases/ClaseDetail'
import { ClaseAdd }  from './Clases/ClaseAdd'
import { MensajesPage } from './Mensajes/MensajesPage' //'./Mensajes/MensajesPage'
import ProfileLayout from './Profile/ProfileLayout'
import PropTypes from 'prop-types';
import NavBar from './Nav/NavBar';
import TopNavBar from './Nav/TopNavBar';

import * as HelperFunctions from '../../Helpers/Functions';

import '../../App.css';
import '../../panel_layout.css';

class DashboardProfesorLayout extends React.Component {

    render(){
      const { match } = this.props
      //for class dashboard change
      const slug = HelperFunctions.slugify( this.props.location.pathname)

    //   useEffect(()=> {
    //     const dispatch = useDispatch()
    //     const getUserData = dispatch(actions.getUserDataAction())
    //     getUserData()
    //   })

      
      return(
        <>
            <NavBar />
            <div className={`container-fluid container-content ${slug === "panel-perfil" ? "" : ""}`}>
                <div className="row vh-100 ">
                    <div id="dashboard" className={`dash-profesor vh-100 col-12 nopadding ${slug}`}>
                        <TopNavBar></TopNavBar>
                        <div className="dash-content">
				            <div className="row" >
                            <Switch>
                                <Route 
                                    exact path={`${match.path}`}
                                    render={(props) => <DashProfesorPage {...props} /> }
                                />
                                <Route path={`${match.path}/materias/`}      component={MateriasPage} />

                                <Route path={`${match.path}/materia/:id`}    component={MateriaDetail} />
                                <Route path={`${match.path}/:materia/clase/:id`}   component={ClaseDetail} />
                                
                                <Route path={`${match.path}/clase/add`}   component={ClaseAdd} /> {/* Esta ruta es temporal, recordar reemplazarla */}
                                {/*
                                <Route path={`${match.path}/materia/:slug/clase/edit/:id`}   component={InscripcionEdit} />

                                <Route path={`${match.path}/estudiante/:id`}    component={EstudianteDetail} />*/}
                                
                                <Route path={`${match.path}/perfil`} component={ProfileLayout} />

                                <Route path={`${match.path}/mensajes`}       component={MensajesPage} />  
                            </Switch>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
                    
           
        </>
      )
   }
 }

DashboardProfesorLayout.propTypes = {
     match: PropTypes.any.isRequired
}

export default DashboardProfesorLayout