import React from 'react'
import { Switch, Route, useParams } from 'react-router-dom'
import { DashAdminPage } from './Admin/DashAdminPage'
import InscripcionAdd from './Inscripciones/InscripcionAdd'
import InscripcionEdit from './Inscripciones/InscripcionEditNuevo'
import { InscripcionesPage } from './Inscripciones/InscripcionesPage'
import { EstudiantesAdminPage } from './Estudiantes/EstudiantesAdminPage'
import { ProfesoresAdminPage } from './Profesores/ProfesoresAdminPage'
import { SemestrePage } from './Semestre/SemestrePage'
import PropTypes from 'prop-types';
import NavBar from './Nav/NavBar';
import TopNavBar from './Nav/TopNavBar';

import '../../App.css';
import '../../panel_layout.css';
import { InscripcionDetail } from './Inscripciones/InscripcionDetail'
import AlumnoAddContainer from './Alumno/AlumnoAddContainer'
import { EstudianteDetail } from './Estudiantes/EstudianteDetail'
import EstudianteEdit from './Estudiantes/EstudianteEdit'
import { MensajesPage } from './Mensajes/MensajesPage' //'./Mensajes/MensajesPage'
import * as HelperFunctions from '../../Helpers/Functions';

const LayoutAdmin = (props) => {

    
    const parameters = useParams()
    const { match } = props
    //for class dashboard change
    const prevSlug = HelperFunctions.slugify( props.location.pathname)
    const prubArray = prevSlug.split('-')
    const slug = prubArray[1] === `inscripcion` ? 'panel-inscripcion' : prevSlug

    return(
    <>
        <NavBar />
        <div className={`container-fluid container-content`}>
            <div className="row vh-100 ">
                <div id="dashboard" className={`dash-admin vh-100 col-12 nopadding ${slug}`}>
                    <TopNavBar></TopNavBar>
                    <div className="dash-content">
                        <div className="row">
                            <div className="container nopadding action-area">
                            <Switch>
                                <Route 
                                    exact path={`${match.path}`}
                                    render={(props) => <DashAdminPage {...props} /> }
                                />
                                <Route path={`${match.path}/alumno/`}               component={AlumnoAddContainer} />
                                <Route path={`${match.path}/semestre/`}             component={SemestrePage} />

                                <Route path={`${match.path}/inscripciones/`}        component={InscripcionesPage} />
                                <Route path={`${match.path}/inscripcion/add`}       component={InscripcionAdd} />
                                <Route path={`${match.path}/inscripcion/edit/:id`}  component={InscripcionEdit} />
                                <Route path={`${match.path}/inscripcion/:id`}       component={InscripcionDetail} />
                                <Route path={`${match.path}/mensajes`}              component={MensajesPage} />  
                                <Route path={`${match.path}/estudiantes`}           component={EstudiantesAdminPage} /> 
                                <Route path={`${match.path}/estudiante/:id`}        component={EstudianteDetail} />
                                <Route path={`${match.path}/estudiante/edit/:id`}   component={EstudianteEdit} />                        
                                <Route path={`${match.path}/profesores`}            component={ProfesoresAdminPage} />
                                
                            </Switch>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
                
        
    </>
    )
   
 }

//  LayoutAdmin.propTypes = {
//      match: PropTypes.any.isRequired
//  }

export default LayoutAdmin